import { useLocation } from "react-router-dom";
import PictureCard from "./PictureCard";

const PictureSheet = ({ cardWordsArray }) => {
    let { pathname } = useLocation();
    const showBackside = pathname === '/backside'
    if (!cardWordsArray?.length) return null

    return <div className='picture-sheet'>
        {
            cardWordsArray.map((cardWords, index) => <PictureCard
                showBackside={showBackside}
                key={index}
                cardWords={cardWords}
            />)
        }
    </div>
}

export default PictureSheet;