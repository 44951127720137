const drawBoardText = (ctx, squareSize) => {
    // "PICTIONARY"
    ctx.save()
    ctx.font = (`${squareSize * .7}px serif`)
    ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2)
    const textPictionary = 'IMAGINARY'
    const textPictionaryWidth = ctx.measureText(textPictionary).width
    for (let i = 0; i < 2; i++) {
        ctx.fillText(textPictionary, -textPictionaryWidth / 2, -.8 * squareSize)
        ctx.rotate(Math.PI)
    }
    ctx.restore()

    // "START" & "END"
    ctx.font = (`${squareSize * .25}px serif`)

    // "START"
    const textStart = 'START'
    const textStartWidth = ctx.measureText(textStart).width
    const textStartLeftOffset = (squareSize - textStartWidth) * 0.5;
    ctx.save()
    ctx.translate(3 * squareSize, 5 * squareSize)
    ctx.rotate(Math.PI * 1.5)
    ctx.fillText(textStart, textStartLeftOffset, -0.05 * squareSize) 

    // "END"
    const textEnd = 'END'
    const textEndApproximateHeight = ctx.measureText(textEnd).fontBoundingBoxAscent
    const textEndWidth = ctx.measureText(textEnd).width
    const textEndLeftOffset = (squareSize - textEndWidth) * 0.5;
    ctx.restore()
    ctx.translate(3 * squareSize, 6 * squareSize)
    ctx.rotate(Math.PI * 0.5)
    ctx.fillText('END', textEndLeftOffset, textEndApproximateHeight)
    ctx.restore()
}

export default drawBoardText;
