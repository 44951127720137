const PictureLine = ({ color, wordEntry }) => {
    const { isShared, word } = wordEntry;
    const colorCategoryMap = {
        yellow: 'P',
        blue: 'V',
        brown: 'H',
        green: 'M',
        red: 'A'
    }

    return <div className={`picture-line ${color}`}>
        <div className='letter'>
            {colorCategoryMap[color]}
        </div>
        <span style={{paddingLeft: '8px'}}>
            {!!isShared && <div className='arrow'></div>}<span className='word'>{word}</span>
        </span>
    </div>
}

export default PictureLine;