import getUpdatedWord from "../util/getUpdatedWord"
import splitToChunks from "../util/splitToChunks";
import generatedWords from '../data/generated-words.json';
import PictureSheet from "./PictureSheet";

const AllCards = () => {
    const enrichedCardWords = generatedWords
        .map(cardWords => cardWords
            .map(cardWord => getUpdatedWord(cardWord))
        )
    const cardWordArraysPerSheet = splitToChunks(enrichedCardWords, enrichedCardWords.length / 9)
    return cardWordArraysPerSheet.map((cardWordsArray, index) => <PictureSheet key={index} cardWordsArray={cardWordsArray} />)
}

export default AllCards;