import { Swiper, SwiperSlide } from 'swiper/react';
import getUpdatedWord from '../util/getUpdatedWord'
import generatedWords from '../data/generated-words.json';
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react';
import shuffleArray from '../util/shuffleArray';
import PictureCard from './PictureCard';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/keyboard';
// import Swiper core and required modules
import { Navigation, Keyboard } from 'swiper/modules';
import { SettingsContext } from '../context';

const enrichedCardWords = generatedWords
    .map(cardWords => cardWords
        .map(cardWord => getUpdatedWord(cardWord))
    )

const shuffledCardsDefault = shuffleArray(enrichedCardWords)

const Play = () => {
    const [context] = useContext(SettingsContext);
    const [counter, setCounter] = useState(context.timerLength)
    const [isCounting, setIsCounting] = useState(false)
    const [indexVisibleSliderCard, setIndexVisibleSliderCard] = useState(0)
    const [isTurning, setIsTurning] = useState(false)
    const [shuffledCards, setShuffledCards] = useState(shuffledCardsDefault)
    const [showBackside, setShowBackside] = useState(true)
    const [pictureCardIsShaking, setPictureCardIsShaking] = useState(false)

    useEffect(() => {
        if (isCounting) {
            const timer = counter > -4 && setInterval(() => setCounter(counter - 0.5), 500);
            // if (counter <= 0) @TODO Phone laten vibreren
            if (counter === -4) {
                setCounter(context.timerLength)
                setIsCounting(false)
            }
            return () => clearInterval(timer);
        }
    }, [counter, isCounting, context.timerLength]);

    if (!shuffledCards?.length) return <Link to='/'>Back to homepage</Link>

    const setPictureShaking = () => {
        setPictureCardIsShaking(true)
        setTimeout(() => {
            setPictureCardIsShaking(false)
        }, 1000);
    }

    const flipCard = () => {
        setIsTurning(true)
        setTimeout(() => {
            setShowBackside(showBackside => !showBackside)
            setTimeout(() => {
                setIsTurning(false)
            })
        }, 216);
    }

    return <div className='play'>
        {/* Hourglass height on CSS pseudo element */}
        <style dangerouslySetInnerHTML={{
            __html: [
                '.timer-background:after {',
                `  height: ${counter / context.timerLength * 80}px;`,
                '}'
            ].join('\n')
        }}></style>
        <Swiper
            modules={[Keyboard, Navigation]}
            className={pictureCardIsShaking ? 'is-shaking' : ''}
            navigation={true}
            keyboard={{ "enabled": true }}
            style={{ width: `${window.screen.width < 350 ? 320 : 390}px`, height: '430px' }}
            slidesPerView={1}
            onSlideChange={() => { if (!showBackside) setShowBackside(true) }}
            onClick={() => { setShowBackside(showBackside => !showBackside) }}
            onRealIndexChange={(swiper) => setIndexVisibleSliderCard(swiper.realIndex)}
        >
            {isCounting &&
                <>
                    <div className={`timer-digit ${counter <= 0 ? 'time-is-up' : ''} ${counter % 1 ? 'on' : ''}`}>{counter > 0 ? Math.floor(counter) : "Time's up!!"}</div>
                    <div className='timer-background' />
                    <div className='timer-triangles' />
                </>
            }
            {
                shuffledCards.slice(0, 80).map((shuffledCard, index) => <div key={index}>
                    <SwiperSlide key={index}>
                        <div className='picture-card-title'>Card {index + 1}</div>
                        <PictureCard
                            isTurning={isTurning}
                            cardWords={shuffledCard}
                            showBackside={showBackside}
                            cardIsVisible={index === indexVisibleSliderCard}
                        />
                    </SwiperSlide>
                </div>)
            }
        </Swiper>
        <div>
            <button
                style={{ backgroundColor: `${isCounting ? 'red' : ''}`, color: `${isCounting ? 'white' : ''}` }}
                className='flip-cart-button d-inline btn btn-warning'
                onClick={() => {
                    setIsCounting(isCounting => !isCounting)
                    if (isCounting) setCounter(context.timerLength)
                }}
            >
                {isCounting ? 'Stop timer' : 'Start timer'}
            </button>
            <button style={{ marginLeft: '15px' }} className='flip-cart-button d-inline' onClick={flipCard}>
                Flip card
            </button>
        </div>
        <div className='bottom-buttons'>
            <button
                className='btn btn-warning'
                onClick={() => {
                    setShowBackside(true)
                    setShuffledCards(shuffleArray(enrichedCardWords));
                    setPictureShaking()
                }
                }>
                Shuffle all cards
            </button>
            <Link style={{ marginLeft: '15px' }} to='/' className='btn'>Back to homepage</Link>
        </div>
    </div>
}

export default Play;