import { useContext } from "react";
import { Link } from "react-router-dom";
import { SettingsContext } from "../context";

const Settings = () => {
    const [context, setContext] = useContext(SettingsContext);
    return <div className='container'>
        <h1>Settings</h1>
        <h2><strong>Timer length</strong></h2>
        {[30, 40, 50, 60].map((timeInSeconds, index) => <button
            style={{
                marginRight: '10px',
                color: `${context.timerLength === timeInSeconds ? '#fff' : ''}`,
                backgroundColor: `${context.timerLength === timeInSeconds ? '#00888a' : ''}`
            }}
            key={index}
            onClick={() => setContext({ ...context, timerLength: timeInSeconds })}
            >
            {timeInSeconds}
        </button>)}
        {/* <h2><strong>Numbers of teams</strong></h2>
        {[1, 2, 3, 4].map((nrOfTeams, index) => <button
            style={{
                marginRight: '10px',
                color: `${context.numberOfTeams === nrOfTeams ? '#fff' : ''}`,
                backgroundColor: `${context.numberOfTeams === nrOfTeams ? '#00888a' : ''}`
            }}
            key={index}
            onClick={() => setContext({ ...context, numberOfTeams: nrOfTeams })}
            >
            {nrOfTeams}
        </button>)} */}
        <div style={{ marginTop: '40px' }}>
            UPCOMING FEATURE: Bord wordt online speelbaar o.b.v. aantal spelers etc.
        </div>
        <div style={{ marginTop: '40px' }}>
            <Link to='/' className='btn'>Back to homepage</Link>
        </div>
    </div>
}

export default Settings;
