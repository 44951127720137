import PictureLine from "./PictureLine";

const PictureCard = ({ cardIsVisible, cardWords, isTurning, showBackside }) => {
    if (!cardWords || cardWords.length !== 5) return null

    return <div className='picture-card'>
        <div className={`backside ${cardIsVisible && !showBackside ? 'd-none' : ''} ${cardIsVisible && showBackside && isTurning ? 'is-turning' : ''}`}>
            <div className='i'>i</div>
            <div className='maginary'>maginary</div>
        </div>
        <div className={`frontside ${cardIsVisible && showBackside ? 'd-none' : ''} ${cardIsVisible && !showBackside && isTurning ? 'is-turning' : ''}`}>
            <div style={{ position: 'relative' }}>
                <h2 style={{ marginTop: '10px' }}><u>Imaginary</u></h2>
                <PictureLine wordEntry={cardWords[0]} color='yellow' />
                <PictureLine wordEntry={cardWords[1]} color='blue' />
                <PictureLine wordEntry={cardWords[2]} color='brown' />
                <PictureLine wordEntry={cardWords[3]} color='green' />
                <PictureLine wordEntry={cardWords[4]} color='red' />
            </div>
        </div>
    </div>
}

export default PictureCard;