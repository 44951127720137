import React, { useRef, useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import createBoardSquares from '../util/createBoardSquares'
import drawBoardSquares from '../util/drawBoardSquares';
// import drawBoardTeams from '../util/drawBoardTeams';
import drawBoardText from '../util/drawBoardText';
// import { SettingsContext } from '../context';

const squareSize = 200;
const amountOfSquares = 11;
const squareBorderWidth = 10;
const boardPadding = 15;

const Canvas = () => {
    // const [settingsContext] = useContext(SettingsContext);
    const canvasRef = useRef(null)
    const [canvasHeight, setCanvasHeight ] = useState(200)

    useEffect(() => {
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        setCanvasHeight(ctx.canvas.offsetHeight)
        const squares = createBoardSquares(squareSize)
        drawBoardSquares(ctx, squares, squareSize, squareBorderWidth)
        // drawBoardTeams(ctx, squareSize, squareBorderWidth, settingsContext)
        drawBoardText(ctx, squareSize)
    }, [])

    return <div style={{ padding: `${boardPadding}px` }}>
        <canvas
            style={{ width: '100%', maxWidth: '700px' }}
            width={squareSize * amountOfSquares + 2 * squareBorderWidth}
            height={squareSize * amountOfSquares + 2 * squareBorderWidth}
            ref={canvasRef}
        />
        <div style={{
            position: 'absolute',
            left: `${boardPadding}px`,
            top: `${canvasHeight + 2 * boardPadding}px`
        }}>
            <Link to='/' className='btn'>Back to homepage</Link>
        </div>
    </div>
}

export default Canvas
