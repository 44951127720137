import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Rules = () => {
    const [isDutch, setIsDutch] = useState(false)
    return <div className='container rules'>
        <Link to='/' className='btn'>Back to homepage</Link>
        <button style={{ marginLeft: '10px' }} onClick={() => setIsDutch(isDutch => !isDutch)}>
            {isDutch ? 'Switch to English' : 'Nederlands'}
        </button>
        {!isDutch ?
            <>
                <h1>Rules</h1>
                <h2>Introduction</h2>
                <p>The Imaginary cards can be used for all types of games, such as Pictionary. For your reference you will find the rules for Pictionary below.</p>
                <h2>Equipment</h2>
                <ul>
                    <li><Link to='/board'>Gameboard</Link></li>
                    <li>One-minute plastic timer (<strong>online</strong>: see <Link to='/play'>Start Timer</Link> button on <Link to='/play'>Play</Link> page)</li>
                    <li>496 cards (<strong>online</strong>: 300 cards, can be shuffled on click)</li>
                    <li>4 category cards (<strong>online</strong>: <a href='#categories'>see categories section)</a></li>
                    <li>Die (<strong>online</strong>: grab it from your home)</li>
                    <li>Paper and pencils (<strong>online</strong>: grab it from your home)</li>
                </ul>
                <h2>The goal</h2>
                <p>To identify through sketched clues as many words as necessary to advance to the <em>End</em> square, and correctly identify the final word.</p>
                <h2>Preparation</h2>
                <p>Place the timer and the cards so all players have access to them. Divide players equally into
                    teams of two to four (see instructions on number of players). Provide each team with pad, pencil,
                    category card and playing piece. Place playing pieces in the <em>Start</em> square on the board. Each team
                    selects a picturist, one who will sketch clues for the first word. Each team rolls the die; highest
                    roll selects the first card. Since all playing pieces begin in the <em>Start</em> square, the first word
                    sketched is an <em>All Play</em> word and all teams participate (see <em>All Play</em> Section). <strong>The die is NOT
                        rolled to advance at the start.</strong></p>
                <h2 id='categories'>Categories</h2>
                <ul>
                    <li><strong>P </strong>- Persoon/dier/plaats/gebouw - i.e. Personal place animal building (including proper names)</li>
                    <li><strong>V </strong>- Voorwerp - i.e. Object. (nouns, things that can be touched or seen)</li>
                    <li><strong>H </strong>- Handeling - i.e. Action. (verbs, things that can be performed)</li>
                    <li><strong>M </strong>- Moeilijk - i.e. Difficult. (challenging words)</li>
                    <li><strong>A </strong>- Allemaal / IEDEREEN SPEELT - i.e. All Play (this can be any type or word or expression)</li>
                    <p>Any word preceded by a triangle (▶) is designated as an <em>All Play</em> word. (Read the All Play section thoroughly).</p>
                </ul>
                <h2>The play</h2>
                <p>The starting picturist selects a word card from the front of the deck. The word corresponding to
                    the colored square in which the playing piece lies is the word in play. The picturist has five
                    seconds to examine the word. The timer is then turned and the picturist begins sketching clues for
                    the team. The picturist may not use verbal or physical communication to teammates during the round. <strong>Sketches may NOT include letters or numbers</strong>. Sketching and guessing continues until the word is
                    identified or until time is up. If a guess is correct, the team continues to play by rolling the die, advancing the number of
                    squares indicated, and selecting a new card and new picturist. Any number of playing pieces may
                    occupy the same square. <strong>THE PICTURIST POSITION ROTATES EVERY TIME A TEAM MUST SKETCH!</strong> The timer is turned for another <strong>60 seconds</strong> for each new word. If a word is not identified within
                    the time limit, play continues to the left. The next team begins its turn by pulling a new card
                    from the deck, NOT by rolling of the die. The ONLY time the die is rolled to advance the playing
                    piece is when a word is identified within the one-minute time limit, or when a team is first to
                    identify the word in any <em>All Play</em> situation. <strong>A team must occupy a square as long as it does
                        not identify the given word.</strong></p>
                <h2>All play</h2>
                <p>In the <em>All Play</em> category, the card is shown to the picturist of each team. The <em>All Play</em> word is
                    sketched simultaneously by picturists to their respective teams at the start of the timer. The first
                    team to identify the word earns control of the die and immediately rolls and advances his playing
                    piece the number of squares indicated. This team now continues its turn with a new word. If no
                    team identifies the word in sixty seconds, play continues to the left. The new team does NOT roll the
                    die, but begins its turn by pulling a new card and sketching the word corresponding to the square
                    currently occupied.<br></br>
                    SPECIAL NOTE! The above rules apply when a triangle (▶) on the card designates a word as an <em>All Play</em>.
                    Remember! Any team that first identifies an <em>All Play</em> word immediately receives the die and
                    rolls, moving the indicated number of squares and then pulling another card.</p>
                <h2>To win</h2>
                <p>The first team to land on the Finish square and guess the word correctly wins the game. The word
                    guessed does not have to be on that team's turn. It can be another team's <em>All Play</em> word.</p>
                <h2>Number of players</h2>
                <p>The number of players per team may be uneven if an odd number of pkyers wish to play. In the case
                    of three players, two teams are formed, and one person acts as the picturist for both teams. This
                    person selects cards and sketches throughout the entire game. The picturist never varies. Normal
                    game rules apply. Play is quicker and more exciting when there are less teams and more players per
                    team. If more than 16 people wish to play, create a fifth team or add more play- ers to each.</p>
                <h2>Stuff</h2>
                <p>How precise an answer must be is up to the teams playing, and should be decided before the start of
                    the game. For example, is <em>combined</em> OK for <em>combine</em>? Or, if the word is <em>puzzle</em>, is <em>Jigsaw puzzle</em> acceptable?</p>
                <h2>Do's and don'ts</h2>
                <p>Players can..</p>
                <ul>
                    <li>Use as many pieces of paper as you want, but try to conserve by using both sides of
                        the paper</li>
                    <li>Use an X can to cross something out, or to indicate an area (like on a map), but NOT use an
                        X as a letter, like brand-X</li>
                    <li>Draw <em>mail</em> for <em>male</em>, or <em>blew</em> for <em>blue</em>, etc.</li>
                    <li>Draw multiple things to represent parts of the word</li>
                </ul>
                <p>Players can NOT..</p>
                <ul>
                    <li>Use letters or numbers</li>
                    <li>Use secret or pre-arranged clues — like drawing an ear for "sounds like"</li>
                    <li>Use dashes to indicate the number of letters in a word</li>
                    <li>Exchange words with their teammates</li>
                    <li>Use sign language</li>
                </ul>
            </> : <>
                <h1>Spelregels</h1>
                <h2>Introductie</h2>
                <p>De Imaginary kaarten kunnen worden gebruikt voor van allerlei spellen. Hieronder vind je de regels voor Pictionary, waar deze kaartjes goed gebruikt voor kunnen worden.</p>
                <h2>Samenvatting</h2>
                <p>Pictionary is een klassiek bordspel waarbij je moet tekenen om te winnen.
                    Jij en je team moeten proberen om bepaalde woorden en zinnen te raden door elkaar aanwijzingen te geven in de vorm van tekeningen.
                    <br></br>
                    <br></br>
                    De tijd en je medespelers zijn tegenstanders in een race waarbij je al tekenend een weg moet banen over het spelbord.
                    De tekenopdrachten variëren van personen, plaatsen, voorwerpen en handelingen tot menig concept waarvan je geen idee hebt hoe je het moet tekenen.
                    Als je op <em>IEDEREEN SPEELT</em> terechtkomt (A / ▶), mag iedereen raden wat het voorstelt.
                    <br></br>
                    <br></br>
                    Je hoeft echter geen kunstenaar te zijn om mee te doen; een beetje fantasie en een snelle hand is alles wat je nodig hebt!
                </p>
                <h2>Spelinhoud</h2>
                <ul>
                    <li><Link to='/board'>Gameboard</Link></li>
                    <li>Zandloper (online: zie <Link to='/play'>Start Timer</Link> knop op <Link to='/play'>Play</Link> pagina)</li>
                    <li>496 speelkaarten (online: 300 kaarten, kunnen op klik worden geschud)</li>
                    <li>4 categoriekaarten (online: <a href='#categories-nl'>zie categorie sectie</a> </li>
                    <li>Dobbelsteen (online: erbij pakken))</li>
                    <li>Potlood/pen en papier (online: gewoon erbij pakken)</li>
                </ul>
                <h2>Doel van het spel</h2>
                <p>Je moet al tekenend proberen het hele spelbord van Start tot Finish (End) te passeren. Het team dat de finish als eerste
                    bereikt en de laatste opdracht goed beantwoordt, wint het spel.</p>
                <h2>Spelvoorbereiding</h2>
                <p>
                    Plaats de zandloper en de kaarten (of mobieltje) binnen handbereik van alle spelers op het speelbord.
                    Verdeel de spelers over gelijke teams (maximaal vier teams), het spel verloopt sneller en spannender als er minder
                    teams meedoen en er meer spelers per team zijn.
                    Geef elke team een potlood, een schetsblok, een categoriekaart en een gekleurde speelpion (die op de Startpositie
                    geplaatst dient te worden). Elk van de teams dient een tekenaar aan te wijzen — een speler die het eerst woord gaat tekenen.
                    <br></br>
                    De dobbelsteen bepaalt de speelvolgorde. Het team dat het hoogste aantal ogen gooit begint.
                </p>
                <h2 id='categories-nl'>Categorieën</h2>
                <p>
                    Op elke opdrachtkaart staan woorden uit 5 categorieën afgebeeld, die stuk voor stuk betrekking hebben op de
                    gekleurde vakjes van het spelbord.
                </p>
                <li><strong>P </strong>- Persoon/dier/plaats/gebouw (ook eigennamen)</li>
                <li><strong>V </strong>- Voorwerp (zaken die zichtbaar of tastbaar zijn)</li>
                <li><strong>H </strong>- Handeling (werkwoorden, handelingen die uitgevoerd kunnen wordne)</li>
                <li><strong>M </strong>- Moeilijk (uitdagende woorden)</li>
                <li><strong>A </strong>- Allemaal, i.e. <em>IEDEREEN SPEELT</em> (Deze categorie bevat de meest uiteenlopende woorden)</li>
                <p>
                    SPECIALE OPMERKING: alle woorden op de kaart die voorafgegaan worden door een driehoekje (▶) lijn ook te
                    gebruiken voor de categorie <em>IEDEREEN SPEELT</em> (zie voor aanvullende informatie het hoofdstuk <em>IEDEREEN SPEELT</em>).
                </p>
                <h2>Spelregels</h2>
                <p>
                    De Startpositie is een geel vakje (Persoon/dier/plaats/gebouw). Vandaal dat het eerste woord tot de gele categorie
                    moet behoren. <strong>Bij de start van het spel wordt de dobbelsteen nog NIET gebruikt</strong>.
                    <br></br>
                    De tekenaar van het team dat mag beginnen neemt de opdrachtkaart die vooraan de houder ligt en kijkt. zonder
                    dat de medespelers het kunnen zien, om welk woord het gaat. Wanneer het een woord is uit de categorie <em>IEDEREEN SPEELT</em> (▶), zie dan de aanwĳzingen onder het kopje <em>IEDEREEN SPEELT</em>.
                    <br></br>
                    <br></br>
                    De zandloper wordt omgedraaid en vanaf dat moment heeft de tekenaar één minuut de tijd om de aanwijzingen
                    voor zijn team op papier te zetten. Het tekenen en raden gaat net zo lang door totdat het woord geraden is, of
                    totdat de tijd om is. Als het woord geraden is, mag het team doorspelen en de dobbelsteen werpen, de pion het
                    aantal gegooide ogen verplaatsen, en een nieuwe tekenaar aanwijzen. Vervolgens mogen ze een nieuwe
                    opdrachtkaart van de stapel nemen en het woord tekenen dat hoort bij de categorie van het gekleurde spelvak
                    waarop hun pion staat. <strong>Bij elk nieuw woord moet het team een andere tekenaar aanwijzen</strong>.
                    <br></br>
                    Zolang een team de dobbelsteen in bezit heeft, mag het doorgaan met tekenen en de dobbelsteen werpen.
                    <br></br>
                    <br></br>
                    Als het woord niet binnen de tijd wordt geraden, dan wordt de dobbelsteen linksom doorgegeven naar het
                    volgende team. Het team dat de dobbelsteen nu in belit krijgt, speelt de volgende beurt door een nieuwe kaart van
                    de voorzijde van de stapel te nemen, en NIET door de dobbelsteen te werpen. De zandloper wordt omgedraaid en
                    de volgende beurt begint.
                    <br></br>
                    <br></br>
                    Het woord dat hoort bij de categorie die overeenkomt met de kleur van het spelvakje waarop de speelpion van het
                    team staat, dient gespeeld te worden. De dobbelsteen mag ALLEEN dan gegooid worden om de speelpion te
                    verplaatsen, wanneer het woord binnen de minuut geraden wordt, of wanneer een team het woord als eerste raadt
                    tijdens een ronde <em>IEDEREEN SPEELT</em> (Zie de aanwijzingen onder het kopje <em>IEDEREEN SPEELT</em>).
                    <br></br>
                    Zolang een team het woord niet raadt, moet de pion op het spelvakje blijven staan.
                    Er kunnen meerdere speelpionnen in een zelfde spelvakje slaan.
                </p>
                <h2>Iedereen speelt</h2>
                <p><em>IEDEREEN SPEELT</em> wordt gespeeld als een team terechtkomt op een <em>IEDEREEN SPEELT</em> positie (rood) van het
                    spelhard of wanneer een woord op de opdrachtkaart voorafgegaan wordt door een driehoekje (▶).
                    Tijdens een ronde van <em>IEDEREEN SPEELT</em> krijgen de tekenaars van elk team het woord te zien.
                    Vervolgens wordt de zandloper omgedraaid en tekenen alle tekenaars tegelijk hetzelfde woord woord voor hun eigen team.
                    <br></br>
                    Ongeacht welk team op <em>IEDEREEN SPEELT</em> terechtkomt, het team dat het woord als eerste raadt, krijgt de
                    dobbelsteen en mag direct gooien en de spelpion het aantal geworpen ogen verplaatsen. Dit team mag de volgende
                    beurt spelen met een nieuw woord.
                    <br></br>
                    <strong>Als geen van de teams het woord raadt binnen de minuut, dan wordt de dobbelsteen naar links doorgegeven</strong>.
                    Dit team mag echter NIET de dobbelsteen gooien, maar moet aan het begin van de spelronde een nieuwe opdrachtkaart
                    nemen en het woord tekenen dat staat aangegeven bij de kleur van het speelvak waarin de pion staat.
                    <br></br>
                    <strong>Denk eraan:</strong> het team dat het eerst gevraagde woord raadt in een <em>IEDEREEN SPEELT</em> ronde krijgt
                    onmiddelijk de dobbelsteen, gooit deze en verplaatst de speelpion het aantal vakjes van de worp, om vervolgens een
                    nieuwe kaart te nemen.
                </p>
                <h2>Winnaar</h2>
                <p>
                    Een team dient het laatste speelvakje in de categorie <em>IEDEREEN SPEELT</em> te bereiken om kans te maken om het spel te winnen
                    (hiervoor is het niet nodig precies het juiste aantal ogen te gooien).
                    <br></br>
                    <br></br>
                    Om te winnen, dient het team de dobbelsteen in bezit te hebben en het woord als eerste te raden.
                    Als geen van de teams het <em>IEDEREEN SPEELT</em> woord binnen de tijd raadt, wordt de dobbelsteen naar links
                    doorgegeven.
                    <br></br>
                    Wanneer een ander team het <em>IEDEREEN SPEELT</em> woord als eerste raadt, wordt de dobbelsteen doorgegeven aan dat
                    team. Het team dat het laatste speelvakje in de categorie <em>IEDEREEN SPEELT</em> bereikt, kan het spel NIET winnen door
                    een ronde te winnen die gecontroleerd wordt door een ander team. Het team moet namelijk eerst proberen de
                    dobbelsteen weer in haar bezit te krijgen, alvorens het een nieuwe poging mag ondernemen.
                    <br></br>
                    <strong>OPMERKING: </strong> voor teams waarvan de pion zich niet in bet laatste spelvakje van de categorie <em>IEDEREEN SPEELT</em> bevindt,
                    gelden de gebruikelijke spelregels.
                </p>
                <h2>Toegestaan en verboden</h2>
                <p>Je mag...</p>
                <ul>
                    <li>Alles tekenen dat verband houdt met het woord, ongeacht hoe subtiel de link is</li>
                    <li>Woorden onderverdelen in een aantal lettergrepen</li>
                    <li>Bijvoorbeeld een "kast" tekenen als de opdracht "ijskast" luidt</li>
                    <li>Iemand "laat" laten raden voor het woordje "laadt" (gelijke uitspraak)</li>
                </ul>
                <p>Je mag NIET...</p>
                <ul>
                    <li>"Oortjes" tekenen als symbool voor "klinkt als"</li>
                    <li>Puntjes tekenen die het aantal letters van het woord aangeven</li>
                    <li>Letters of cijfers gebruiken</li>
                    <li>Woorden wisselen met je teamgenoten</li>
                    <li>Gebarentaal gebruiken</li>
                </ul>
                <h2>Nauwkeurigheid</h2>
                <p>Hoe nauwkeurig het antwoord op een opdracht moet zijn wordt bepaald door de spelende teams en dient voor
                    aanvang van het spel besproken te worden. Wordt "tweepersoonsbed" bijvoorbeeld goed gerekend als het gezochte
                    woord "lits-jumaux" luidt? En keur je alleen het woord 'Kok" goed als gezocht wordt naar "Wim Kok"? En wat doen
                    met een begrip als "vergroten" in plaats van "vergroot"?</p>
                <h2>Variaties</h2>
                <p>
                    <strong>Andere hand: </strong>De tekenaars moeten hun andere hand gebruiken (dus links voor rechtshandigen en andersom).
                    <br></br>
                    <strong>Ogen dicht: </strong>Tekenaars moeten tekenen met gesloten ogen. Stiekem gluren = verlies.
                    <br></br>
                    <strong>Eén lijn: </strong>De tekenaars moeten de tekening met één ononderbroken lijn maken. Optillen = verlies.
                </p>
                <p>
                    Denk er tenslotte aan: vrienden heb je voor het leven, niet alleen voor een avond PICTIONARY!
                </p>
            </>
        }
        <Link to='/' className='btn'>Back to homepage</Link>
    </div>
}

export default Rules
