// https://stackoverflow.com/questions/37328583/drawing-multiple-squares-on-the-canvas?noredirect=1&lq=1
const draw = (x, y, color, width, height) => {
    let shape = {};
    shape.left = x;
    shape.top = y;
    shape.width = width;
    shape.height = height;
    shape.color = color;
    return shape;
}

export default draw;
