const drawBoardSquares = (ctx, squares, squareSize, squareBorderWidth) => {
    let i = 0;
    for (i = 0; i < squares.length; i++) {
        // Draw colored squares
        ctx.fillStyle = squares[i].color;
        ctx.fillRect(squares[i].left, squares[i].top, squares[i].width, squares[i].height);
        ctx.fillStyle = '#000'
        // Draw black borders
        const borderLengthHorizontal = squares[i].width + squareBorderWidth ;
        const borderLengthVertical = squares[i].height + squareBorderWidth ;
        ctx.fillRect(squares[i].left, squares[i].top, borderLengthHorizontal, squareBorderWidth) // horizontal top
        ctx.fillRect(squares[i].left, squares[i].top + squareSize, borderLengthHorizontal, squareBorderWidth) // horizontal bottom
        ctx.fillRect(squares[i].left, squares[i].top, squareBorderWidth, borderLengthVertical) // vertical left
        ctx.fillRect(squares[i].left + squareSize, squares[i].top, squareBorderWidth, borderLengthVertical) // vertical right
    }
}

export default drawBoardSquares;