import draw from "./draw"

// https://stackoverflow.com/questions/37328583/drawing-multiple-squares-on-the-canvas?noredirect=1&lq=1
const createBoardSquares = (squareSize) => {
    let squares = []
    const green = '#32bd7a';
    const blue = '#72bcef';
    const red = '#f06f5d';
    const yellow = '#fdf92a';
    const brown = '#f6c55e';

    // Line 1
    squares.push(draw(0 * squareSize, 0, blue, squareSize, squareSize));
    squares.push(draw(1 * squareSize, 0, brown, squareSize, squareSize));
    squares.push(draw(2 * squareSize, 0, green, squareSize, squareSize));
    squares.push(draw(3 * squareSize, 0, red, squareSize, squareSize));
    squares.push(draw(4 * squareSize, 0, yellow, squareSize, squareSize));
    squares.push(draw(6 * squareSize, 0, blue, squareSize, squareSize));
    squares.push(draw(7 * squareSize, 0, brown, squareSize, squareSize));
    squares.push(draw(8 * squareSize, 0, green, squareSize, squareSize));
    squares.push(draw(9 * squareSize, 0, red, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 0, yellow, squareSize, squareSize));
    // Line 2
    squares.push(draw(0 * squareSize, 1 * squareSize, yellow, squareSize, squareSize));
    squares.push(draw(4 * squareSize, 1 * squareSize, blue, squareSize, squareSize));
    squares.push(draw(6 * squareSize, 1 * squareSize, yellow, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 1 * squareSize, blue, squareSize, squareSize));
    // Line 3
    squares.push(draw(0 * squareSize, 2 * squareSize, red, squareSize, squareSize));
    squares.push(draw(4 * squareSize, 2 * squareSize, brown, squareSize, squareSize));
    squares.push(draw(5 * squareSize, 2 * squareSize, green, squareSize, squareSize));
    squares.push(draw(6 * squareSize, 2 * squareSize, red, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 2 * squareSize, brown, squareSize, squareSize));
    // Line 4
    squares.push(draw(0 * squareSize, 3 * squareSize, green, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 3 * squareSize, green, squareSize, squareSize));
    // Line 5
    squares.push(draw(0 * squareSize, 4 * squareSize, brown, squareSize, squareSize));
    squares.push(draw(1 * squareSize, 4 * squareSize, blue, squareSize, squareSize));
    squares.push(draw(2 * squareSize, 4 * squareSize, yellow, squareSize, squareSize));
    squares.push(draw(8 * squareSize, 4 * squareSize, blue, squareSize, squareSize));
    squares.push(draw(9 * squareSize, 4 * squareSize, yellow, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 4 * squareSize, red, squareSize, squareSize));
    // Line 6
    squares.push(draw(8 * squareSize, 5 * squareSize, brown, squareSize, squareSize));
    // Line 7
    squares.push(draw(0 * squareSize, 6 * squareSize, brown, squareSize, squareSize));
    squares.push(draw(1 * squareSize, 6 * squareSize, green, squareSize, squareSize));
    squares.push(draw(2 * squareSize, 6 * squareSize, red, squareSize, squareSize));
    squares.push(draw(8 * squareSize, 6 * squareSize, green, squareSize, squareSize));
    squares.push(draw(9 * squareSize, 6 * squareSize, red, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 6 * squareSize, yellow, squareSize, squareSize));
    // Line 8
    squares.push(draw(0 * squareSize, 7 * squareSize, blue, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 7 * squareSize, blue, squareSize, squareSize));
    // Line 8
    squares.push(draw(0 * squareSize, 8 * squareSize, yellow, squareSize, squareSize));
    squares.push(draw(4 * squareSize, 8 * squareSize, brown, squareSize, squareSize));
    squares.push(draw(5 * squareSize, 8 * squareSize, blue, squareSize, squareSize));
    squares.push(draw(6 * squareSize, 8 * squareSize, yellow, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 8 * squareSize, brown, squareSize, squareSize));
    // Line 9
    squares.push(draw(0 * squareSize, 9 * squareSize, red, squareSize, squareSize));
    squares.push(draw(4 * squareSize, 9 * squareSize, green, squareSize, squareSize));
    squares.push(draw(6 * squareSize, 9 * squareSize, red, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 9 * squareSize, green, squareSize, squareSize));
    // Line 10
    squares.push(draw(0 * squareSize, 10 * squareSize, green, squareSize, squareSize));
    squares.push(draw(1 * squareSize, 10 * squareSize, brown, squareSize, squareSize));
    squares.push(draw(2 * squareSize, 10 * squareSize, blue, squareSize, squareSize));
    squares.push(draw(3 * squareSize, 10 * squareSize, yellow, squareSize, squareSize));
    squares.push(draw(4 * squareSize, 10 * squareSize, red, squareSize, squareSize));
    squares.push(draw(6 * squareSize, 10 * squareSize, green, squareSize, squareSize));
    squares.push(draw(7 * squareSize, 10 * squareSize, brown, squareSize, squareSize));
    squares.push(draw(8 * squareSize, 10 * squareSize, blue, squareSize, squareSize));
    squares.push(draw(9 * squareSize, 10 * squareSize, yellow, squareSize, squareSize));
    squares.push(draw(10 * squareSize, 10 * squareSize, red, squareSize, squareSize));
    return squares;
}

export default createBoardSquares;