import { Routes, Route, Link, useLocation } from 'react-router-dom'
import { useState } from 'react';

import AllCards from './components/AllCards';
import Canvas from './components/Canvas';
import PictureLine from './components/PictureLine';
import Play from './components/Play';
import Rules from './components/Rules';
import Settings from './components/Settings';
import { SettingsContext } from './context';
import './App.scss';

function App() {
  const [context, setContext] = useState({timerLength: 60, numberOfTeams: 1});
  const location = useLocation()
  const homepageCardWord1 = { link: '/play', isShared: true, word: 'Play game' };
  const homepageCardWord2 = { link: '/board', isShared: false, word: 'Board' };
  const homepageCardWord3 = { link: '/frontside', isShared: false, word: 'Print' };
  const homepageCardWord4 = { link: '/rules', isShared: false, word: 'Rules' };
  const homepageCardWord5 = { link: '/settings', isShared: false, word: 'Settings' };

  return (
    <div className={['/', '/board'].indexOf(location.pathname) > -1 ? 'background-blue' : ''}>
      <SettingsContext.Provider value={[context, setContext]}>
        <Routes>
          <Route exact path="/play" element={<Play />} />
          <Route exact path="/backside" element={<AllCards />} />
          <Route exact path="/frontside" element={<AllCards />} />
          <Route exact path="/board" element={<Canvas />} />
          <Route exact path="/rules" element={<Rules />} />
          <Route exact path="/settings" element={<Settings />} />
          <Route path="/" element={
            <div style={{ margin: 'auto' }} className='picture-card'>
              <h2><u>Imaginary</u></h2>
              <Link to={homepageCardWord1.link}><PictureLine wordEntry={homepageCardWord1} color='yellow' /></Link>
              <Link to={homepageCardWord2.link}><PictureLine wordEntry={homepageCardWord2} color='blue' /></Link>
              <Link to={homepageCardWord3.link}><PictureLine wordEntry={homepageCardWord3} color='brown' /></Link>
              <Link to={homepageCardWord4.link}><PictureLine wordEntry={homepageCardWord4} color='green' /></Link>
              <Link to={homepageCardWord5.link}><PictureLine wordEntry={homepageCardWord5} color='red' /></Link>
            </div>
          } />
        </Routes>
      </SettingsContext.Provider>
    </div>
  );
}

export default App;
